import React from "react";
import { HeaderConfig, appliedConfig } from "../config/commonConfig";

function Features() {
  return (
    <section
      id="features"
      className={
        "section " +
        (appliedConfig.appliedHeader === HeaderConfig.SideHeaderDark ||
        appliedConfig.appliedHeader === HeaderConfig.SideHeaderLight
          ? "px-lg-4 "
          : " ")
      }
    >
      <div className="container">
  <div className="row">
    <div className="col-lg-4 align-self-center order-2 order-lg-1">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-12">
          <div className="featured-box featured-box-reverse-xl style-3 mb-5">
            <div className="featured-box-icon bg-body-secondary rounded-circle">
              <i className="fas fa-pencil-ruler" />
            </div>
            <h3>Innovación y desarrollo de productos</h3>
            <p>
              Estamos continuamente trabajando en mejorar nuestras herramientas
              para así ofrecer soluciones que realmente marquen la diferencia.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-12">
          <div className="featured-box featured-box-reverse-xl style-3 mb-5">
            <div className="featured-box-icon bg-body-secondary rounded-circle">
              <i className="fas fa-cloud-upload-alt" />
            </div>
            <h3>Accesibilidad y soporte al cliente</h3>
            <p>
              Nos aseguramos que nuestro sistema sea fácil de usar y que
              nuestros clientes cuenten con el apoyo necesario cuando lo
              necesiten.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div className="col-lg-4 text-center mb-4 mb-xl-0 order-1">
      <img
        alt=""
        src="images/app-showcase-feature.png"
        className="img-fluid"
      />
    </div>
    <div className="col-lg-4 align-self-center order-3">
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-12">
          <div className="featured-box style-3 mb-5">
            <div className="featured-box-icon bg-body-secondary rounded-circle">
              <i className="fas fa-lock" />
            </div>
            <h3>Colaboración y relación a largo plazo</h3>
            <p>
              Trabajamos junto a nuestros clientes, entendiendo y ayudando a
              alcanzar sus metas.
            </p>
          </div>
        </div>
        <div className="col-md-6 col-lg-12">
          <div className="featured-box style-3 mb-5">
            <div className="featured-box-icon bg-body-secondary rounded-circle">
              <i className="fas fa-paper-plane" />
            </div>
            <h3>Expansión de la presencia de marca</h3>
            <p>
              Nos enfocamos en aumentar nuestra visibilidad y presencia en el
              mercado, para llegar a más emprendedores y PYMEs que puedan
              beneficiarse de nuestras soluciones.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </section>
  );
}

export default Features;
